import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/default"
import NewsArticle from "../components/news/NewsArticle"
import Page from "../components/Page"
import usePageHeader from "../hooks/usePageHeader"
import { H1, H2 } from "../styles"
import SEO from "../components/seo"

export default function NewsPage({ data }) {
  const articles = data.allContentfulNews.nodes
  const [pageHeaderRef, pageHeaderHeight] = usePageHeader()

  return (
    <Layout>
      <SEO title="Company & Product News" />
      <Page as="main" pageHeaderHeight={pageHeaderHeight}>
        <Page.Header as="header" columnWidth={600} ref={pageHeaderRef}>
          <Page.Subtitle>Our most important news</Page.Subtitle>
          <H1 as={H2}>Company &amp; Product News</H1>
        </Page.Header>
        {articles.map((article) => (
          <NewsArticle key={article.contentful_id} article={article} />
        ))}
      </Page>
    </Layout>
  )
}

export const pageQuery = graphql`
  query newsPage {
    allContentfulNews(
      filter: { slug: { ne: "demo" } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        date(formatString: "dddd, D MMMM YYYY")
        title
        contentful_id
        body {
          raw
          references {
            ... on ContentfulAsset {
              title
              contentful_id
              __typename
              fluid(maxWidth: 2560) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            ... on ContentfulVimeo {
              contentful_id
              name
              url
            }
            ... on ContentfulPost {
              contentful_id
              slug
              title
              __typename
            }
          }
        }
      }
    }
  }
`
