import React from "react"
import styled from "styled-components"
import { H2, Smaller } from "../../styles"
import Container from "../Container"
import Article from "../knowledge/Article"

const ArticleContainer = styled(Container)`
  padding-bottom: 8rem;

  + article {
    header {
      box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.colors.offBlack};
    }
  }
`
const ArticleHeader = styled.header`
  padding-top: 8rem;
  margin-bottom: 2.4rem;
`

const ArticleSubtitle = styled(Smaller)`
  display: inline-block;
  margin: 0 0 1.2rem 0;
  color: ${({ theme }) => theme.colors.grey};
`

export default function NewsArticle({ article }) {
  return (
    <ArticleContainer as="article" columnWidth={600}>
      <ArticleHeader>
        <ArticleSubtitle>{article.date}</ArticleSubtitle>
        <H2>{article.title}</H2>
      </ArticleHeader>
      {article.body && <Article.Body body={article.body} />}
    </ArticleContainer>
  )
}
